import React from "react";
import { ExpandButton } from "../styled_components/default";
import ColapsedItem from "./ColapsedItem";

import dashboardImage from '../../assets/images/menu-dashboard-icon.svg';
import menuResultIcon from '../../assets/images/menu-result-icon.svg';
import menuUploadIcon from '../../assets/images/menu-upload-icon.svg';
import registrationIcon from 'iconmenu-cadastros.svg';
import financialManagementIcon from 'iconmenu-gestao-financeira.svg';
import menuParameterizationIcon from "../../assets/images/menu-parameterization-icon.svg";

class LateralMenu extends React.Component {
    state = {
        activeItem: 'dashboard',
        collapsed: true,
        customParamLinks: [],
        customParamLinksRegistrations: [],
        customParamLinksFinancialManagements: [],
        expandItemSelected: ''
    };

    componentDidMount(){
        let {can_balance_sheets, can_cost_centers, can_budgets, can_contabil_patterns, can_formulas, can_charts, can_front_dashboard_formulas, can_indicators,
            can_financial_management_bank_accounts,
            can_financial_management_contracts,
            can_financial_management_indebtedness,
            can_financial_management_movements,
            can_contract_financial_accounts,
            can_clients,
            can_products,
            can_providers,
            can_services_provideds,
            can_indexers,
            can_technical_manager_contracts,
            can_financial_institutions,
            can_financial_purposes,
            can_contract_types,
            can_markings,
            can_contract_situations,
            can_bank_statements,
            is_contract_user,
            is_read_user
        } = this.props.authorizations

        if (this.props.can_edit_contabil_pattern) {
            this.setState({customParamLinks: [
                    (can_contabil_patterns ? { label: "Padrão de Contas", href: "/contabil_pattern/configure" } : null),
                    (can_balance_sheets ? { label: "Parametrização - De/Para", href: "/balance_sheets/new" } : null),


                ], customParamLinksRegistrations:[
                    (can_clients ? { label: "Cliente/Fornecedor", href: "/clients" } : null),
                    (can_products ? { label: "Produtos", href: "/products" } : null),
                    (can_services_provideds ? { label: "Serviços", href: "/services_provideds" } : null),
                    (can_indexers ? { label: "Indexadores", href: "/indexers" } : null),
                    ({ label: "Marcações", href: "/markings" }),
                    ({ label: "Status do Contrato", href: "/contract_situations" }),
                    ({ label: "Filiais", href: "/subsidiaries" }),
                    (can_contract_types ? { label: "Tipo de Contrato", href: "/contract_types" } : null),
                    (can_technical_manager_contracts ? { label: "Responsável Técnico", href: "/technical_manager_contracts" } : null),
                    (can_financial_institutions ? { label: "Instituição Financeira", href: "/financial_institutions" } : null),
                    (can_financial_purposes ? { label: "Finalidade", href: "/financial_purposes" } : null)
                ],
                customParamLinksFinancialManagements: [
                    (can_financial_management_movements ? { label: "Lançamentos Manuais", href: "/financial_management_movements" } : { label: "Lançamentos Manuais", href: "/financial_management_movements" }),
                    (can_financial_management_contracts ? { label: "Contratos", href: "/financial_management_contracts" } : null),
                    (can_financial_management_indebtedness ? { label: "Endividamentos", href: "/financial_management_indebtednesses" } : null),
                    (can_financial_management_bank_accounts ? { label: "Contas Bancárias", href: "/financial_management_bank_acccounts" } : null)

                ]})
        }

        if (is_read_user) {
            this.setState({ 
                customParamLinksFinancialManagements: [
                    ({ label: "Contratos", href: "/financial_management_contracts" }),
                    ({ label: "Endividamentos", href: "/financial_management_indebtednesses" })
                ]
            })
        }

        if(is_contract_user){
            this.setState({ customParamLinksRegistrations:[
                    (can_clients ? { label: "Cliente/Fornecedor", href: "/clients" } : null),
                    (can_products ? { label: "Produtos", href: "/products" } : null),
                    (can_services_provideds ? { label: "Serviços", href: "/services_provideds" } : null),
                    (can_indexers ? { label: "Indexadores", href: "/indexers" } : null),
                    (can_markings ? { label: "Marcações", href: "/markings" } : null),
                    (can_contract_situations ? { label: "Status do Contrato", href: "/contract_situations" } : null),
                    (can_contract_types ? { label: "Tipo de Contrato", href: "/contract_types" } : null),
                    (can_technical_manager_contracts ? { label: "Responsável Técnico", href: "/technical_manager_contracts" } : null),
                    (can_financial_institutions ? { label: "Instituição Financeira", href: "/financial_institutions" } : null),
                    (can_financial_purposes ? { label: "Finalidade", href: "/financial_purposes" } : null)
                ],
                customParamLinksFinancialManagements: [
                    (can_financial_management_contracts ? { label: "Contratos", href: "/financial_management_contracts" } : null),
                    (can_financial_management_indebtedness ? { label: "Endividamentos", href: "/financial_management_indebtednesses" } : null),
                    (can_financial_management_bank_accounts ? { label: "Contas Bancárias", href: "/financial_management_bank_acccounts" } : null)

                ]})
        }
    }



setActiveItem = function(id) {
        this.setState({ activeItem: id });
        this.setState({collapsed: false})
    };


    activeInclude (path) {
        const urlPath = window.location.pathname
        if (urlPath === path) {
            return true
        }

        if (path === 'upload' && (urlPath === '/balance_sheets' || urlPath === '/cost_centers' || urlPath === '/budgets/upload')) {
            return true
        }
        if (path === 'registration' && (urlPath === '/clients' || urlPath === '/providers' || urlPath === '/products' || urlPath === '/services_provideds'|| urlPath === '/type_categories')) {
            return true
        }
        if (path === 'financial_management' && (urlPath === '/financial_management_contracts' || urlPath === '/financial_management_bank_acccounts' || urlPath === '/financial_management_indebtednesses' || urlPath === '/financial_management_movements')) {
            return true
        }


        if (path === 'parametrization' && (urlPath === '/contabil_pattern/configure' || urlPath === '/formulas' || urlPath === '/charts' ||
            urlPath === '/front_dashboard_formulas' || urlPath === '/indicators' || urlPath === '/balance_sheets/new')) {
            return true
        }
    }

    expandItem (item) {
        this.setState({
            expandItemSelected: item,
            collapsed: false
        })
    }

    toggleCollapse(){
        if(!this.state.collapsed){
            this.expandItem(null)
        }
        this.setState({collapsed: !this.state.collapsed})
    }

    render() {
        let { collapsed, expandItemSelected } = this.state;
        let {
                can_balance_sheets, can_contabil_patterns,
                is_contract_user, is_read_user
            } = this.props.authorizations

        return (
            <div style={{width: collapsed ? 100 : 260, transition: "all 300ms ease-in-out", overflow: "hidden"}}>

                <ColapsedItem
                    key={"Dashboard"}
                    collapsed={collapsed}
                    onClick={() => this.onClick("dashboard")}
                    active={this.activeInclude("dashboard")}
                    href="/"
                    label="Dashboard"
                    icoSrc={dashboardImage}
                />

                {
                    !is_contract_user &&  <ColapsedItem
                        key={"Resultado"}
                        has_inconsistence={this.props.has_inconsistence}
                        collapsed={collapsed}
                        active={this.activeInclude("/results")}
                        href="/results"
                        label="Resultado"
                        icoSrc={menuResultIcon}
                    />

                }
                 <ColapsedItem
                        key={"Gestão Financeira"}
                        collapsed={collapsed}
                        onClick={() => this.expandItem("financial_management")}
                        active={this.activeInclude("financial_management")}
                        expanded={expandItemSelected === 'financial_management'}
                        label="Gestão Financeira"
                        icoSrc={financialManagementIcon}
                        submenuLinks={[
                            ...this.state.customParamLinksFinancialManagements
                        ]}
                    />

                {
                    !is_read_user && <ColapsedItem
                        key={"Cadastro"}
                        collapsed={collapsed}
                        onClick={() => this.expandItem("registration")}
                        active={this.activeInclude("registration")}
                        expanded={expandItemSelected === 'registration'}
                        label="Cadastro"
                        icoSrc={registrationIcon}
                        submenuLinks={[
                            ...this.state.customParamLinksRegistrations
                        ]}
                    />
                }

                {
                    !is_contract_user && !is_read_user && <ColapsedItem
                        key={"Upload"}
                        collapsed={collapsed}
                        onClick={() => this.expandItem("upload")}
                        active={this.activeInclude("upload")}
                        expanded={expandItemSelected === 'upload'}
                        label="Upload"
                        icoSrc={menuUploadIcon}
                        submenuLinks={[
                            (can_balance_sheets ? { label: "Upload de arquivo", href: "/integrations" }: null),
                            (can_contabil_patterns ? { label: "Padrão de arquivo", href: "/file_pattern/configure" } : null),

                        ]}
                    />
                }

                {
                    !is_contract_user && !is_read_user &&  <ColapsedItem
                        key={"Parametrização"}
                        collapsed={collapsed}
                        onClick={() => this.expandItem("parametrization")}
                        active={this.activeInclude("parametrization")}
                        expanded={expandItemSelected === 'parametrization'}
                        label="Parametrização"
                        icoSrc={menuParameterizationIcon}
                        submenuLinks={[
                            ...this.state.customParamLinks
                        ]}
                    />
                }

                <ExpandButton onClick={()=> this.toggleCollapse()} active={collapsed}/>
            </div>
        );
    }
}

export default LateralMenu;
