import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import {FlexCenter} from '../styled_components/default'
import {Box, Link, LinkWrapper, Logo, HiddenOptions, HiddenLink, VerticalLine, Notification, Notifications, NotificationWrapper} from '../styled_components/header/box'
import CompanySelect from "./CompanySelect"
import user_change_ico from 'ico_user.svg';
import comment_mention_ico from 'ico_msg.svg';
import card_alteration_ico from 'ico_change.svg';
import { ClickAwayListener } from "@material-ui/core";
import SystemSelector from "./system_select/index.js"
class Header extends React.Component {
  state = {}
  async setCurrentCompany(id, url="/"){
    let confirmation = confirm("Você deseja mudar de empresa?")
    if (confirmation) {
      let result = await axios.post(`/set_current_company.json`, {
        company_id: id
      });
      Turbolinks.visit(url)  
    }
    
  }

  goToNotification(id, notificationCompanyId){
    let url = `/notifications/${id}/visit`
    if (this.props.currentCompanyId != notificationCompanyId) {
      this.setCurrentCompany(notificationCompanyId, url)
    }else{
      Turbolinks.visit(url)
    }
    
  }

  async fetchNotifications(){
    let result = await axios.get(`/notifications.json`);
    
    this.setState({notifications: result?.data?.data})
    this.setState({
      nonReadCount: result?.data?.data?.filter((n)=>( !n.attributes?.already_opened)).length
    })
    
  }
  
  componentDidMount(){
    this.fetchNotifications()

  }

  notificationTypeIco(notificationType){
    switch (notificationType) {
      case 'comment_mention':
        return comment_mention_ico
      case 'card_alteration':
        return card_alteration_ico
      default:
        return user_change_ico
    }
  }

  showNotifications(){
    this.setState({notification_visibility: !this.state.notification_visibility})
    //axios post to mark as visited
    axios.post(`/notifications/opened.json`)
  }

  ellipsisSubstring(str, maxLength){
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "..."
    }else{
      return str
    }
  }

  render () {
    const { picture, companies, user_scope, can_update_company, user_name, all_companies } = this.props;
    const {config_menu_visibility, notification_visibility, notifications, nonReadCount} = this.state;


    return (
      <Box>
        <SystemSelector></SystemSelector>

        <VerticalLine/>

        {(user_scope === "company_user" || user_scope === "contract_user")  &&
          // <select onChange={this.setCurrentCompany.bind(this)}>
          //   {this.props.companies.map((company)=>{
          //     return <option selected={this.props.currentCompanyId == company.id} value={company.id}>{company.label}</option>
          //   })}
          // </select>

          <CompanySelect 
            currentCompanyId={this.props.currentCompanyId}
            onChange={this.setCurrentCompany.bind(this)}
            options={companies}
            all_options={all_companies}
          />

        }
        <div style={{display: 'flex', justifyContent: 'flex-end', flex: 1, margin: 30}}>
        	<FlexCenter>
            {user_scope === "saga_admin" && <React.Fragment>
              <Link href="/companies">Empresas</Link>
              <Link href="/users">Usuários</Link>
              <Link href="/admins/menu">Configuração Master</Link>
            </React.Fragment>}
            
        		
        	</FlexCenter>

          <FlexCenter>
            <LinkWrapper style={{zIndex: 5, position: 'relative'}}>
              
              <div onClick={()=> this.showNotifications()}>
                <i className={`fa fa-bell`} style={{color: '#676767'}}/>
                <Link >Notificações</Link>
              </div>
              {nonReadCount > 0 && <NonReadNotificationsCounter>
                {nonReadCount}
              </NonReadNotificationsCounter>}
              {notification_visibility && <NotificationWrapper>
                <ClickAwayListener onClickAway={()=> this.setState({notification_visibility: false}) }>
                    <Notifications alignRight={true} width={420} height={60} tabIndex="0"  >
                    {notifications?.map((notification)=>{
                      let attr = notification.attributes
                      return(<Notification visited={attr.visited} onClick={()=> this.goToNotification(attr.id, attr?.company?.id)}  >
                        <div style={{display: 'flex', alignItems: 'center', height: 60}}>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                              <img style={{width: 40, marginRight: 16}} src={this.notificationTypeIco(attr?.notification_type)}></img>
                            </div>
                            <div>
                              <CompanyName>
                                {attr?.company?.name}
                              </CompanyName>
                              <NotificationDescription dangerouslySetInnerHTML={{__html: this.ellipsisSubstring(attr.description, 150)}} >
                              </NotificationDescription>
                              <NotificationDate>
                                há {attr.time_ago}
                              </NotificationDate>
                            </div>
                          </div>
                          <div>
                            {
                              attr.visited ? <></> : <GreenBall></GreenBall>
                            }
                            
                          </div>
                        </div>
                        
                      </Notification>)
                    })}
                    
                  </Notifications>
                </ClickAwayListener>
              </NotificationWrapper>}
              
            </LinkWrapper>
            <VerticalLine/>
            {/* {user_scope == "company_user" && can_update_company && <React.Fragment>
              <Link href="/edit_company">Configuração</Link>
            </React.Fragment>} */}
            
            {/*<Link>Notificações</Link>*/}
            <LinkWrapper style={{zIndex: 5, position: 'relative'}} >
              <img
                src={picture}
                style={{width: 50, height: 50, padding: 6, borderRadius: 50, border: '1px #e5e5e5 solid'}}
              />
              <div onClick={()=> this.setState({config_menu_visibility: !config_menu_visibility})}>
                <Link onClick={()=> this.setState({config_menu_visibility: !config_menu_visibility})}>{user_name}</Link>
                <i className={`fa fa-chevron-${config_menu_visibility ? "up" : "down"}`} style={{color: '#676767'}}/>
              </div>
              {config_menu_visibility && <HiddenOptions alignRight={true} top={77}>
                {(user_scope === "company_user" || user_scope === "contract_user")  && can_update_company &&
                  <HiddenLink href="/edit_company" >
                    <Icon className={'fa fa-cog'}> </Icon>Configurações
                  </HiddenLink>
                }
                <HiddenLink href="/users/edit?profile=true">
                  <Icon className={'fa fa-user'}> </Icon>Perfil
                </HiddenLink>
                <HiddenLink disabled={true}  target={"_blank"} href="https://cleverview.com.br/central-ajuda/tutorial/">
                  <Icon className={'fa fa-user'}> </Icon>Tutorial
                </HiddenLink>
                <HiddenLink href="/users/sign_out" data-method="delete">
                  <Icon className={'fa fa-sign-out'}> </Icon>Sair
                </HiddenLink>
              </HiddenOptions>}
              
            </LinkWrapper>
          </FlexCenter>
        </div>
      </Box>
      
    );
  }
}

export default Header
const Icon = styled.i`
  margin-right: 10px
`
const NonReadNotificationsCounter = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background: #ff0000;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  font-weight: bold;

`
const GreenBall = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #31b7bc;
`

const CompanyName = styled.div`
  height: 20px;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #617e94;
  text-transform: uppercase;
  margin-bottom: 10px;
`
 const NotificationDescription = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: #617e94  ;
  margin-bottom 10px;

 `

 const NotificationDate = styled.div`
  flex-grow: 0;
  font-family: Roboto;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #617e94;
  margin-bottom: 10px;
 `