import React, { useState } from 'react';
import cleverviewFinImage from './images/fin_menu.png';
import gtImage from './images/gt_menu.png';
import { Logo } from '../../styled_components/header/box';
// Dados dos sistemas com imagens importadas
const systemsData = [
  {
    name: 'Cleverview Fin',
    image: cleverviewFinImage,
    url: 'https://sistema.cleverview.com.br',
  },
  {
    name: 'Cleverview GT',
    image: gtImage,
    url: 'https://sistema.cleverview.com.br/consulting',
  },
];

const SystemSelector = () => {
  const [showOptions, setShowOptions] = useState(false);

  const handleSystemClick = () => {
    setShowOptions(!showOptions);
  };

  const handleSelectSystem = (system) => {
    window.open(system.url, '_blank');
  };
  

  return (
    <div>
      <div onClick={handleSystemClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <Logo />
        <div
          style={{
            transform: showOptions ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
            position: 'relative',
            left: '-28px',
          }}
        >
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.5L5 7.5L10 2.5H0Z" fill="white" />
          </svg>
        </div>
      </div>

      {/* Lista de sistemas disponíveis */}
      {showOptions && (
        <div style={{ position: 'absolute', backgroundColor: '#23323f', padding: '8px', width: 260, zIndex: 9 }}>
          {systemsData
            .map((system) => (
              <div key={system.name} onClick={() => handleSelectSystem(system)} style={{border: "solid 1px #617e94", padding: "16px 25px", marginBottom: '18px', cursor: 'pointer' }}>
                <img src={system.image} alt={system.name} style={{width: '100%', height: 'auto'}}  />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SystemSelector;
